import React, { Suspense, lazy, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useUserState } from "../../app-context/auth-context/AuthState";
import { http } from "../../helper/http";

import DashbaordWater from "./DashbaordWater";
import DashboardPiezo from "./DashboardPiezo";
import DashboardTotalizer from "./DashboardTotalizer";
import DashboardTemp1 from "./DashboardTemp1";
import DashboardTemp2 from "./DashboardTemp2";
import DashboardWaterQuality2 from "./DashboardWaterQuality2";
import DashboardWaterQuality3 from "./DashboardWaterQuality3";
import DashboardWaterQuality4 from "./DashboardWaterQuality4";
import DashboardWaterQuality5 from "./DashboardWaterQuality5";
import DashboardWaterQuality6 from "./DashboardWaterQuality6";
import DashboardEnergy1 from "./enargy/DashboardEnergy1";
import DashboardAutomation1 from "./DasboardAutomation1";
import DashboardAutomation2 from "./DashboardAutomation2";
import DashboardVibration from "./DashboardVibration";
import DashboardVibration1 from "./DashboardVibration1";
import DashboardVibration2 from "./DashboardVibration2";
import DashboardWaterTotalizerJoin from "./DashboardWaterTotalizerJoin";
import DashboardAtlanta from "./atlanta/DashboardAtlanta";
import DashboardSolar1 from "./solar/DashboardSolar1";
import DashboardDg from "./dg/DashboardDg";
import DashboardTemperature from "./temperature/DashboardTemperature";
import LevelMonitoring from "./level/LevelMonitoring";
import TotalizerJoin from "./TotalizerJoin";
import M20tesxt from "./420/M-20test";
import DashboardTotalLizerFlowrateBattery from "./DashboardTotalLizerFlowrateBattery";
import DashboardWaterTamplateAuthority from "./DashboardWaterTamplateAuthority";
import Dashboard420MassLevel from "./Dashboard420MassLevel";
import DashboardPulseWaterTemplate from "./DashboardPulseWaterTemplate";
import DashboardEnergy2 from "./enargy/DashboardEnergy2";
import DashboardEnergySinglePhase from "./enargy/DashboardEnergySinglePhase";
import DashboardAcController from "./DashboardAcController";
import DashboardBuzMonitoring from "./DashboardBuzMonitoring";
const DashboardWaterQualityLzy = lazy(() => import("./DashboardWaterQuality"));
const DashboardTotalizerFlowRate = lazy(
  () => import("./DashboardTotalLizerFlowrate")
);
const DashboardTotalizerFlowPump = lazy(
  () => import("./DashboardWaterTotalizerFlowPump")
);

const baseUrl = process.env.REACT_APP_BASE_URL;

interface LocationState {
  deviceId: number | null;
  deviceName: string | null;
}

interface Device {
  id: number;
  template: number;
  // Add other properties as needed
}

const CustomerDashboard: React.FC = () => {
  const location = useLocation();
  const locationState = location.state as LocationState | null;

  const [deviceId, setDeviceId] = useState<number | null>(
    locationState?.deviceId || null
  );
  const [deviceName, setDeviceName] = useState<string | null>(
    locationState?.deviceName || null
  );
  const { users }: any = useUserState();

  const [selectedDevice, setSelectedDevice] = useState<number | null>(deviceId);
  const [selectedTemplate, setSelectedTemplate] = useState<number | null>(null);
  const [deviceList, setDeviceList] = useState<Device[]>([]);
  const [devices, setDevices] = useState<Device | null>(null);

  useEffect(() => {
    if (deviceId) {
      fetchData();
    }
  }, [deviceId]);

  const fetchData = async () => {
    if (!deviceId) return;

    try {
      const [allDevicesResponse, assignedDevicesResponse] = await Promise.all([
        http(`${baseUrl}/device?alldevice=""`) as Promise<{
          devices: Device[];
        }>,
        http(`${baseUrl}/assign-device?userId=${users.user.id}`) as Promise<{
          devices: { devices: { Device: Device }[] };
        }>,
      ]);

      let allDevices = allDevicesResponse.devices || [];
      let assignedDevices =
        assignedDevicesResponse.devices?.devices?.map((item) => item.Device) ||
        [];

      let combinedDevices = [...allDevices, ...assignedDevices];
      let uniqueDevices = Array.from(new Set(combinedDevices.map((d) => d.id)))
        .map((id) => combinedDevices.find((d) => d.id === id))
        .filter((d): d is Device => d !== undefined);

      setDeviceList(uniqueDevices);

      const device = uniqueDevices.find((d) => d.id === deviceId);
      if (device) {
        setDevices(device);
        setSelectedTemplate(device.template);
        setSelectedDevice(deviceId);
      } else {
        console.error("Device not found");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <Box className="dashboard-container mx-2">
        <h1 style={{ fontSize: "28px" }}>Dashboard</h1>
        <h1 style={{ fontSize: "20px" }}>Device Name: {deviceName}</h1>
        <div className="notification-container">
          <i className="fas fa-bell"></i>
        </div>
      </Box>
      {selectedTemplate === 5 && (
        <DashbaordWater
          devicesProps={devices}
          selectedDevice={selectedDevice}
        />
      )}
      {selectedTemplate === 6 && (
        <DashboardPiezo devices={devices} selectedDevice={selectedDevice} />
      )}
      <Suspense fallback={<div>Loading...</div>}>
        {selectedTemplate === 7 && (
          <DashboardWaterQualityLzy
            devices={devices}
            selectedDevice={selectedDevice}
          />
        )}
        {selectedTemplate === 8 && (
          <DashboardVibration selectedDevice={selectedDevice} />
        )}
        {selectedTemplate === 9 && (
          <DashboardTotalizer
            devices={devices}
            selectedDevice={selectedDevice}
          />
        )}
        {selectedTemplate === 10 && (
          <DashboardTemp1 devices={devices} selectedDevice={selectedDevice} />
        )}
        {selectedTemplate === 11 && (
          <DashboardAutomation1
            devices={devices}
            selectedDevice={selectedDevice}
          />
        )}
        {selectedTemplate === 12 && (
          <DashboardWaterQuality2
            devices={devices}
            selectedDevice={selectedDevice}
          />
        )}
        {selectedTemplate === 13 && (
          <DashboardWaterQuality3
            devices={devices}
            selectedDevice={selectedDevice}
          />
        )}
        {selectedTemplate === 14 && (
          <DashboardEnergy1 devices={devices} selectedDevice={selectedDevice} />
        )}
        {selectedTemplate === 15 && (
          <DashboardTotalizerFlowRate
            devices={devices}
            selectedDevice={selectedDevice}
          />
        )}
        {selectedTemplate === 16 && (
          <DashboardTotalizerFlowPump
            devices={devices}
            deviceId={deviceId}
            selectedDevice={selectedDevice}
          />
        )}
        {selectedTemplate === 17 && (
          <DashboardWaterQuality4
            devices={devices}
            selectedDevice={selectedDevice}
          />
        )}
        {selectedTemplate === 18 && (
          <DashboardWaterQuality5
            devices={devices}
            selectedDevice={selectedDevice}
          />
        )}
        {selectedTemplate === 19 && (
          <DashboardWaterQuality6
            devices={devices}
            selectedDevice={selectedDevice}
          />
        )}
        {selectedTemplate === 20 && (
          <DashboardTemp2 selectedDevice={selectedDevice} />
        )}
      </Suspense>
      {selectedTemplate === 21 && (
        <DashboardAutomation2
          deviceId={deviceId}
          devices={devices}
          selectedDevice={selectedDevice}
        />
      )}
      {selectedTemplate === 22 && (
        <DashboardVibration1
          devices={devices}
          selectedDevice={selectedDevice}
        />
      )}
      {selectedTemplate === 23 && (
        <DashboardVibration2
          devices={devices}
          selectedDevice={selectedDevice}
        />
      )}
      {selectedTemplate === 24 && (
        <DashboardWaterTotalizerJoin
          devices={devices}
          selectedDevice={selectedDevice}
        />
      )}
      {selectedTemplate === 27 && (
        <DashboardAtlanta devices={devices} selectedDevice={selectedDevice} />
      )}
      {selectedTemplate === 26 && (
        <DashboardSolar1 devices={devices} selectedDevice={selectedDevice} />
      )}
      {selectedTemplate === 25 && (
        <DashboardDg devices={devices} selectedDevice={selectedDevice} />
      )}
      {selectedTemplate === 28 && (
        <DashboardTemperature
          devices={devices}
          selectedDevice={selectedDevice}
        />
      )}
      {selectedTemplate === 29 && (
        <LevelMonitoring devices={devices} selectedDevice={selectedDevice} />
      )}
      {selectedTemplate === 31 && (
        <TotalizerJoin devices={devices} selectedDevice={selectedDevice} />
      )}
      {selectedTemplate === 32 && (
        <M20tesxt d={devices} selectedDevice={selectedDevice} />
      )}
      {selectedTemplate === 33 && (
        <DashboardTotalLizerFlowrateBattery
          devices={devices}
          selectedDevice={selectedDevice}
        />
      )}
      {selectedTemplate === 34 && (
        <DashboardWaterTamplateAuthority
          devices={devices}
          selectedDevice={selectedDevice}
        />
      )}
      {selectedTemplate === 35 && (
        <Dashboard420MassLevel
          devices={devices}
          selectedDevice={selectedDevice}
        />
      )}
      {selectedTemplate === 37 && (
        <DashboardPulseWaterTemplate
          devices={devices}
          selectedDevice={selectedDevice}
        />
      )}
      {selectedTemplate === 38 && (
        <DashboardEnergy2 devices={devices} selectedDevice={selectedDevice} />
      )}
      {selectedTemplate === 41 && (
        <DashboardEnergySinglePhase
          devices={devices}
          selectedDevice={selectedDevice}
        />
      )}
      {selectedTemplate === 42 && (
        <DashboardAcController
          deviceId={deviceId}
          devices={devices}
          selectedDevice={selectedDevice}
        />
      )}
      {selectedTemplate === 43 && (
        <DashboardBuzMonitoring
          deviceId={deviceId}
          devices={devices}
          selectedDevice={selectedDevice}
        />
      )}
    </>
  );
};

export default CustomerDashboard;
