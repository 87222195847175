const indianStateCityData = {
  states: [
    {
      name: "Andaman and Nicobar",
      cities: ["Car Nicobar", "Diglipur", "Port Blair"]
    },
    {
      name: "Andhra Pradesh",
      cities: ["Amaravati", "Guntur", "Kakinada", "Rajahmundry", "Tirupati", "Vijayawada", "Visakhapatnam"]
    },
    {
      name: "Arunachal Pradesh",
      cities: ["Itanagar", "Pasighat", "Tawang", "Tezu", "Ziro"]
    },
    {
      name: "Assam",
      cities: ["Dibrugarh", "Guwahati", "Jorhat", "Nagaon", "Silchar"]
    },
    {
      name: "Bihar",
      cities: ["Bhagalpur", "Gaya", "Munger", "Muzaffarpur", "Patna"]
    },
    {
      name: "Chandigarh",
      cities: ["Chandigarh"]
    },
    {
      name: "Chhattisgarh",
      cities: ["Bhilai", "Bilaspur", "Durg", "Korba", "Raipur"]
    },
    {
      name: "Dadra and Nagar Haveli and Daman and Diu",
      cities: ["Daman", "Diu", "Silvassa"]
    },
    {
      name: "Delhi",
      cities: ["Dwarka", "Karol Bagh", "New Delhi", "Rohini", "Saket"]
    },
    {
      name: "Goa",
      cities: ["Mapusa", "Margao", "Panaji", "Vasco da Gama"]
    },
    {
      name: "Gujarat",
      cities: ["Ahmedabad", "Bhavnagar", "Jamnagar", "Rajkot", "Surat", "Vadodara"]
    },
    {
      name: "Haryana",
      cities: ["Ambala", "Chandigarh", "Faridabad", "Gurugram", "Panipat"]
    },
    {
      name: "Himachal Pradesh",
      cities: ["Dharamshala", "Kangra", "Kullu", "Manali", "Shimla"]
    },
    {
      name: "Jammu and Kashmir",
      cities: ["Anantnag", "Baramulla", "Jammu", "Srinagar", "Udhampur"]
    },
    {
      name: "Jharkhand",
      cities: ["Bokaro", "Dhanbad", "Giridih", "Jamshedpur", "Ranchi"]
    },
    {
      name: "Karnataka",
      cities: ["Bangalore", "Belagavi", "Davangere", "Hubli", "Mangalore", "Mysuru", "Tumkur"]
    },
    {
      name: "Kerala",
      cities: ["Kochi", "Kottayam", "Kozhikode", "Palakkad", "Thiruvananthapuram", "Thrissur"]
    },
    {
      name: "Ladakh",
      cities: ["Kargil", "Leh"]
    },
    {
      name: "Lakshadweep",
      cities: ["Agatti", "Kavaratti", "Minicoy"]
    },
    {
      name: "Madhya Pradesh",
      cities: ["Bhopal", "Gwalior", "Indore", "Jabalpur", "Sagar", "Ujjain"]
    },
    {
      name: "Maharashtra",
      cities: ["Aurangabad", "Mumbai", "Nagpur", "Nashik", "Navi Mumbai", "Pune", "Solapur", "Thane"]
    },
    {
      name: "Manipur",
      cities: ["Churachandpur", "Imphal", "Thoubal"]
    },
    {
      name: "Meghalaya",
      cities: ["Nongstoin", "Shillong", "Tura"]
    },
    {
      name: "Mizoram",
      cities: ["Aizawl", "Lunglei", "Saiha"]
    },
    {
      name: "Nagaland",
      cities: ["Dimapur", "Kohima", "Mokokchung"]
    },
    {
      name: "Odisha",
      cities: ["Berhampur", "Bhubaneswar", "Cuttack", "Rourkela", "Sambalpur"]
    },
    {
      name: "Puducherry",
      cities: ["Karaikal", "Mahe", "Puducherry", "Yanam"]
    },
    {
      name: "Punjab",
      cities: ["Amritsar", "Bathinda", "Chandigarh", "Jalandhar", "Ludhiana", "Patiala"]
    },
    {
      name: "Rajasthan",
      cities: ["Ajmer", "Bikaner", "Jaipur", "Jodhpur", "Kota", "Udaipur"]
    },
    {
      name: "Sikkim",
      cities: ["Gangtok", "Namchi", "Pakyong"]
    },
    {
      name: "Tamil Nadu",
      cities: ["Chennai", "Coimbatore", "Erode", "Madurai", "Salem", "Tiruchirappalli", "Tirunelveli"]
    },
    {
      name: "Telangana",
      cities: ["Hyderabad", "Khammam", "Nizamabad", "Warangal"]
    },
    {
      name: "Tripura",
      cities: ["Agartala", "Ambassa", "Udaipur"]
    },
    {
      name: "Uttar Pradesh",
      cities: ["Agra", "Allahabad", "Gorakhpur", "Kanpur", "Lucknow", "Meerut", "Varanasi"]
    },
    {
      name: "Uttarakhand",
      cities: ["Dehradun", "Haridwar", "Nainital", "Rishikesh", "Roorkee"]
    },
    {
      name: "West Bengal",
      cities: ["Asansol", "Durgapur", "Howrah", "Kolkata", "Medinipur", "Siliguri"]
    }
  ]
};

export default indianStateCityData;
